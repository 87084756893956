.brands {
  overflow: hidden;
}

.brands-header {
  font-size: 40px;
  text-transform: capitalize;
  line-height: 1.2em;
  letter-spacing: -0.1px;
  color: #54595f;
  font-weight: 600;
}

.wrapper {
  display: grid;
  grid-template-columns: 190px 190px 190px 190px;
  grid-template-rows: 180px;
  justify-content: center;
  padding: 2px;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  padding-bottom: 20px;
}

.wrapper-div {
  justify-content: center;
  box-shadow: 0px 0px 3px 3px grey;
  border-radius: 3px;
}

.wrapper-div:hover {
  box-shadow: 0px 0px 7px 7px grey;
  cursor: pointer;
}

.hide-button {
  position: relative;
  right: 2%;
}

.pagination {
  display: inline-block;
}

@media (max-width: 500px) {
  .brands-header {
    font-size: 30px;
  }
  .wrapper {
    grid-template-columns: 100px 100px;
    grid-template-rows: 100px 100px;
  }
  .brand-images {
    width: 100px;
    height: 100px;
  }
  .wrapper-div {
    padding-top: 0px;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
  .brands-header {
    font-size: 30px;
  }
  .wrapper {
    grid-template-columns: 120px 120px 120px 120px;
    grid-template-rows: 120px;
  }
  .brand-images {
    width: 120px;
    height: 120px;
  }
  .wrapper-div {
    padding-top: 0px;
  }
}
