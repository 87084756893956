.App {
  margin: 0px;
  overflow-x: hidden;
}

h1,
h2 {
  font-family: "Open Sans", sans-serif;
}

p,
a {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}
