.footer-outer-div {
  margin-top: 40px;
  height: 100%;
  background-color: #424242;
}

.footer-header {
  padding-top: 2%;
  display: flex;
  justify-content: space-around;
  color: white;
}
.fb {
  font-size: 40px;
  border-radius: 5px;
  margin: 10px;
}
.fb:hover {
  box-shadow: 0px 0px 7px 7px grey;
  cursor: pointer;
}

.working-hours {
  margin-bottom: 10px;
}
.working-hours > div {
  padding-top: 15px;
}

.location > div {
  padding-top: 15px;
}

.menu > div {
  padding-top: 15px;
}

.contact > div {
  padding-top: 15px;
}

.entities:hover {
  cursor: pointer;
  color: black;
}

@media (max-width: 500px) {
  .footer-header {
    display: block;
  }
  .footer-content {
    padding-top: 3%;
  }
  .fb {
    padding-top: 2%;
  }
}
@media (min-width: 501px) and (max-width: 900px) {
  .footer-header {
    display: block;
  }
}
