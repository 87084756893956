.root {
  text-align: center;
}
.services-container {
width: 100%;
}

.container {
  overflow: hidden;
  height: 210px;
  width: 70%;
  background-color: rgb(50, 64, 167);
  margin: 30px auto 30px auto;
  display: flex;
  border-radius: 10px;
}

.services-child-image {
  flex: 1;
  width: 400px;
  padding-right: 20px;
}

.first-image {
  height: 210px;
  width: 130%;
}

.float-child-info {
  flex: 3;
  padding: 0px 15px 15px 15px;
  margin-left: 40px;
  margin-top: 60px;
  color: white;
}

.third-image-info {
  padding-left: 50px;
}

.third-image {
  width: 135%;
}

@media (max-width: 900px) {
  .container {
    display: block;
    margin: 30px auto 5px auto;
    height: 30%;
    width: 70%;
  }
  .services-child-image {
    width: 120%;
    height: 70%;
  }
  .first-image {
    width: 60%;
    height: 30%;
    margin-right: 15%;
    padding-top: 10px;
  }
  .float-child-info {
    margin-top: 5px;
    display: contents;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
  .first-image {
    width: 45%;
    height: 30%;
    margin-right: 20%;
    padding-top: 10px;
  }
}
