.main-container {
  width: 100%;
}
.contact-us-container {
  display: inline-block;
  vertical-align: top;
  margin-left: 2%;
}

.form-container {
  padding-right: 30px;
}

.t-grid {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-row: 50px 50px;
  row-gap: 25px;
}

.t-name {
  width: 280px;
  margin-right: 5px;
}

.t-email {
  width: 280px;
  margin-right: 5px;
}

.t-contact {
  width: 280px;
  margin-right: 5px;
}

.textfield-message {
  width: 880px;
}

.send-button {
  padding-top: 20px;
}

.contact-us-icon {
  padding-top: 90px;
}

.shop-info {
  padding-top: 10px;
  text-align: center;
}

.address-container {
  color: white;
  display: flex;
  justify-content: space-between;
  background-color: #424242;
  padding-top: 10px;
}

.digital-card {
  padding-left: 10px;
  height: 180px;
  width: 330px;
}

@media (max-width: 900px) {
  .talk-to-us {
    text-align: center;
    position: relative;
    right: 5px;
  }
  .form-container {
    padding: 0px;
  }

  .contact-us-container {
    display: block;
  }
  .t-grid {
    grid-template-columns: 62%;
    grid-row: 50px 50px 50px;
    row-gap: 10px;
  }
  .textfield-message {
    padding-top: 20px;
    width: 158%
  }
  .send-button {
    text-align: center;
    position: relative;
    right: 5px;
  }
  .address-container {
    background-color: #424242;
    display: block;
    text-align: center;
  }
  .t-name {
    width: 126%;
    margin-right: 0px;
  }
  .t-email {
    width: 126%;
    margin-right: 0px;
  }
  .t-contact {
    width: 126%;
    margin-right: 0px;
  }
  .contact-us-icon {
    display: none;
  }
  .card {
    width: 70%;
    padding: 0px;
  }
}


@media (min-width: 501px) and (max-width: 900px) {
  .card {
    width: 40%;
    padding: 0px;
  }
}
