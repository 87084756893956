.p-header {
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-header-isRelative .p-header-inner {
  position: relative;
}

.p-header-inner {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: white;
}

.p-header-name {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 110%;
}

.p-header-logo {
  position: relative;
  display: contents;
  float: left;
  max-width: 5%;
}

.p-logo-image {
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  margin-top: -7px;
}

.tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 2rem;
  overflow: hidden;
  background-image: -webkit-linear-gradient(
    to bottom,
    #0000d6,
    #3d00e0,
    #5300e8,
    #6002ee,
    #7e3ff2,
    #9965f4
  );
  background-image: linear-gradient(
    to bottom,
    #0000d6,
    #3d00e0,
    #5300e8,
    #6002ee,
    #7e3ff2,
    #9965f4
  );
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #c7006e;
}

@media (max-width: 900px) {
  .p-header-bottom {
    width: 100%;
  }
  .p-header-bottom-container {
    text-align: center;
  }
}
