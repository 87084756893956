.airtronic-header {
  font-size: 40px;
  text-transform: capitalize;
  line-height: 1.2em;
  letter-spacing: -0.1px;
  color: white;
  font-weight: 600;
  display: contents;
}

.float-container {
  display: flex;
  background-color: #283593;
  clip-path: polygon(0 0, 100% 0, 100% 76%, 0 100%);
}

.float-child-image {
  flex: 1;
  padding: 10px 5px 10px 5px;
}

.header-image-about-card {
  position: relative;
  right: -10px;
  margin-bottom: 20px;
  width: 250px;
  height: 250px;
}

.float-child-info-header {
  flex: 3;
  padding: 15px 50px 15px 50px;
  margin-top: 50px;
  position: relative;
  right: 50px;
  color: white;
}

@media (max-width: 500px) {
  .float-container {
    display: block;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .float-child-image {
    width: 5%;
    height: 250px;
    padding: 3px;
    display: contents;
  }
  .header-image-about-card {
    width: 70%;
    padding-top: 20px;
    right: 0px;
  }

  .float-child-info-header {
    padding-top: 0px;
    margin-top: 5%;
    right: 0px;
    color: white;
  }
  .airtronic-header {
    font-size: 20px;
    color: white;
  }
}

@media (min-width: 501px) and (max-width: 900px) {
  .float-container {
    display: flex;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .float-child-image {
    width: 5%;
    height: 250px;
    padding: 3px;
    display: contents;
  }
  .header-image-about-card {
    width: 35%;
    padding-top: 20px;
  }
  .float-child-info-header {
    padding-top: 0px;
    margin-top: 5%;
    right: 0px;
    color: white;
  }
  .airtronic-header {
    font-size: 23px;
    color: white;
  }
}
