.product-container {
  height: 100px;
  width: 170px;
  box-shadow: 0px 0px 3px 3px grey;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;
}

.ul-wrapper {
  justify-content: center;
  justify-items: center;
  padding: 2px;
  grid-column-gap: 25px;
  grid-row-gap: 35px;
  padding-bottom: 20px;
  list-style-type: "";
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  display: contents;
}

.icon-class {
  font-size: 50px;
  padding-top: 7px;
}

.hide-button {
  cursor: pointer;
}

.product-label {
  font-size: 20px;
  position: relative;
  bottom: 20px;
}

.products-div {
  background-image: -webkit-linear-gradient(
    to bottom,
    #64b5f6,
    #90caf9,
    #bbdefb
  );
  background-image: linear-gradient(to bottom, #64b5f6, #90caf9, #bbdefb);
}

.products-div:hover {
  box-shadow: 0px 0px 5px 5px grey;
  cursor: pointer;
}

@media (max-width: 900px) {
  .product-container {
    width: 100px;
    height: 50px;
  }
  .icon-class {
    font-size: 25px;
    padding-top: 4px;
  }
  .product-label {
    font-size: 12px;
    bottom: 12px;
  }
  .product-outer-container {
    width: 100%;
    justify-content: center;
  }
}
